<template>
  <div class="borrow-edit">
    <div class="main">
      <van-form @submit="onSubmit"
                ref="vanForm"
                :disabled="isClick"
                @failed="onFail">
        <van-field v-model="borrowInfo.lendingStartDate"
                   name="领取日期"
                   required
                   label="领取日期"
                   placeholder="请填写"
                   :rules="[{ required: true, message: '领取日期不能为空' }]"
                   @click="showCalendarBtn('single', 'lendingStartDate')">
          <template #input>
            <div :class="{ disabled: isClick }"
                 v-if="borrowInfo.lendingStartDate">
              {{ borrowInfo.lendingStartDate }}
            </div>
            <div v-if="!borrowInfo.lendingStartDate"
                 class="disabled">请选择</div>
          </template>
        </van-field>
        <van-field v-model="borrowInfo.lendingEndDate"
                   name="归还日期"
                   required
                   label="归还日期"
                   placeholder="请填写"
                   :rules="[{ required: true, message: '归还日期不能为空' }]"
                   @click="showCalendarBtn('single', 'lendingEndDate')">
          <template #input>
            <div :class="{ disabled: isClick }"
                 v-if="borrowInfo.lendingEndDate">
              {{ borrowInfo.lendingEndDate }}
            </div>
            <div v-if="!borrowInfo.lendingEndDate"
                 class="disabled">请选择</div>
          </template>
        </van-field>
        <van-field v-model="borrowInfo.organizationName"
                   name="单位"
                   label="单位"
                   placeholder="请填写"
                   required
                   readonly
                   @click="pickerBtn('organizationName')"
                   :rules="[{ required: true, message: '单位不能为空' }]">
          <template #input>
            <div v-if="borrowInfo.organizationName"
                 :class="{ disabled: isClick }">{{ borrowInfo.organizationName }}</div>
            <div v-if="!borrowInfo.organizationName"
                 class="disabled">请选择</div>
          </template>
        </van-field>
        <van-field v-model="borrowInfo.storagePlace"
                   name="存放位置"
                   required
                   label="存放位置"
                   placeholder="请填写"
                   :rules="[{ required: true, message: '存放位置不能为空' }]" />
        <van-field v-model="borrowInfo.pickName"
                   name="提货人"
                   label="提货人"
                   placeholder="请填写" />
        <van-field v-model="borrowInfo.pickMobileNumber"
                   name="联系电话"
                   label="联系电话"
                   placeholder="请填写" />

        <van-field v-model="borrowInfo.assetIds"
                   name="借用资产"
                   label="借用资产"
                   required
                   @click.stop="addAsset"
                   placeholder="请填写"
                   :rules="[{ required: true, trigger: 'onBlur', message: '借用资产不能为空' }]">
          <template #input>
            <van-button type="primary"
                        :disabled="isClick"
                        native-type="button"
                        size="mini">添加资产</van-button>
          </template>
        </van-field>

        <van-collapse v-model="collapseList">
          <van-collapse-item v-for="item in borrowList"
                             :key="item.id"
                             :title="item.name"
                             :name="item.id">
            <div class="content">
              <div class="content-line">
                <span class="name">资产编码:</span>
                <span class="value">{{ item.code }}</span>
              </div>
              <div class="content-line">
                <span class="name">资产图片:</span>
                <van-uploader disabled
                              :deletable="false"
                              :show-upload="false"
                              v-if="item.srcList && item.srcList.length"
                              v-model="item.srcList" />
                <span v-else>暂无数据</span>
              </div>
              <div class="content-line">
                <span class="name">品牌:</span>
                <span class="value">{{ item.brandName }}</span>
              </div>
              <div class="content-line">
                <span class="name">规格型号:</span>
                <span class="value">{{ item.specifications }}</span>
              </div>
              <div class="content-line">
                <span class="name">计量单位:</span>
                <span class="value">{{ item.measuringUnit }}</span>
              </div>
              <div class="content-line">
                <span class="name">存放位置:</span>
                <span class="value">{{ item.storagePlace }}</span>
              </div>
              <div class="content-line">
                <span class="name">所属单位:</span>
                <span class="value">{{ item.manufacturer }}</span>
              </div>
              <div class="content-line">
                <span class="name">操作:</span>
                <van-button type="danger"
                            native-type="button"
                            size="mini"
                            :disabled="isClick"
                            @click="borrowRemove(item.id)">删除</van-button>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div class="action">
          <van-button class="save"
                      round
                      block
                      type="info"
                      :disabled="isClick"
                      native-type="submit">保存</van-button>
        </div>
      </van-form>

      <!-- 选择弹框 -->
      <van-popup v-model="showPopup"
                 round
                 position="bottom">
        <custom-popup :title="pickerTitle"
                      :columns="pickerColumns"
                      @cancel="showPopup = false"
                      @confirm="pickerConfirm" />
      </van-popup>
      <!-- 选择日期 -->
      <van-calendar v-model="showCalendar"
                    :type="calendarType"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm="onConfirm" />
      <!-- 添加资产弹框 -->

      <van-dialog v-model="showDialog"
                  id="van-dialog"
                  @confirm="dialogConfirm"
                  class="van-dialog"
                  show-cancel-button>
        <div class="header">
          <div class="search">
            <van-dropdown-menu>
              <!-- get-container="#main" -->
              <van-dropdown-item class="menu-item"
                                 v-model="screen.searchKey"
                                 :options="searchTypeOptions" />
            </van-dropdown-menu>
            <van-search class="search-content"
                        v-model.trim="screen.searchContent"
                        @search="onSearch"
                        placeholder="请输入搜索关键词" />
            <div class="search-btn"
                 @click="onSearch">搜索</div>
          </div>
          <div class="filter">
            <div class="filter-item"
                 @click="pickerBtn('assetCategory')">
              <span>{{ filter.status.label }}</span>
              <van-icon class="filter-down"
                        name="arrow-down" />
            </div>
          </div>
        </div>
        <div class="dialog-content">
          <van-list v-model="listStatus.loading"
                    :finished="listStatus.finished"
                    finished-text="没有更多了"
                    @load="onLoad">
            <van-collapse v-model="collapse"
                          accordion>
              <van-checkbox-group v-model="selectList">
                <template v-for="item in list">
                  <van-checkbox class="dialog-checkbox"
                                label-disabled
                                :name="item.id">
                    <van-collapse-item :key="item.id"
                                       :title="item.name"
                                       :name="item.id">
                      <div class="content">
                        <div class="content-line">
                          <span class="name">资产编码:</span>
                          <span class="value">{{ item.code }}</span>
                        </div>
                        <div class="content-line">
                          <span class="name">资产分类:</span>
                          <span class="value">{{ item.assetCategory }}</span>
                        </div>
                        <div class="content-line">
                          <span class="name">品牌:</span>
                          <span class="value">{{ item.brandName }}</span>
                        </div>
                        <div class="content-line">
                          <span class="name">规格型号:</span>
                          <span class="value">{{ item.specifications }}</span>
                        </div>
                        <div class="content-line">
                          <span class="name">计量单位:</span>
                          <span class="value">{{ item.measuringUnit }}</span>
                        </div>
                        <div class="content-line">
                          <span class="name">存放位置:</span>
                          <span class="value">{{ item.storagePlace }}</span>
                        </div>
                        <div class="content-line">
                          <span class="name">所属单位:</span>
                          <span class="value">{{ item.manufacturer }}</span>
                        </div>
                        <div class="content-line">
                          <span class="name">商品价格:</span>
                          <span class="value">{{ item.price }}</span>
                        </div>
                      </div>
                    </van-collapse-item>
                  </van-checkbox>
                </template>
              </van-checkbox-group>
            </van-collapse>
          </van-list>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/assets/borrowEdit.less";

import { SUCCESS_CODE } from "@/apis/notification";
import Filter from "ldap-filters";

import { flatMap, find } from "lodash-es";
import customPopup from "@/components/customPopup.vue";
let vm;
export default {
  components: { customPopup },
  name: "borrowDetail",
  data() {
    return {
      collapseList: [],
      pickerColumns: [],
      collapse: "",
      pickerTitle: "",
      calendarType: "",
      minDate: "",
      maxDate: "",
      showCalendar: false,
      showPopup: false,
      showDialog: false,
      listStatus: {
        loading: true,
        finished: false
      },

      borrowInfo: {
        lendingNumber: "",
        lendingStartDate: "",
        lendingEndDate: "",
        organizationName: "",
        pickName: "",
        pickMobileNumber: "",
        applicantName: "",
        storagePlace: "",
        status: "",
        items: [],
        assetIds: ""
      },
      screen: {
        assetCategory: "",
        searchContent: "",
        searchKey: "code",

        usingStatus: "AVAILABLE",
        status: "ENABLE"
      },
      pager: {
        pageNumber: 1,
        pageSize: 5,
        totalRecords: 0
      },
      list: [],
      borrowList: [],
      assetsList: [],
      selectList: [],
      organizationList: [],
      isClick: false,
      searchTypeOptions: [
        { text: "资产编码", value: "code" },
        { text: "资产名称", value: "name" },
        { text: "品牌名称", value: "brandName" }
      ],
      filter: {
        status: {
          id: "",
          label: "资产分类"
        },
        title: "资产分类"
      }
    };
  },
  methods: {
    async init() {
      vm = this;
      this.isClick = false;
      this.minDate = new Date(this.$moment().add(-1, "y").format("YYYY-MM-DD"));
      this.maxDate = new Date(this.$moment().add(1, "y").format("YYYY-MM-DD"));
      let { id } = this.$route.query;
      if (id) {
        this.getAssetLendingsId(id);
        this.isClick = true;
      }
      this.getOrganizationList();
    },

    initData() {},

    pickerBtn(type, isClick = false) {
      if (isClick || this.isClick) return;
      this.showPopup = true;
      if (type == "organizationName") {
        this.pickerTitle = "使用单位";
        this.pickerColumns = this.organizationList.map(val => {
          return { text: val.name, key: "organizationName", id: val.name };
        });
      }
      if (type == "assetCategory") {
        this.filter.title = "资产分类";
        this.pickerColumns = [{ text: "全部分类", key: "assetCategory", id: "" }].concat(
          this.assetsList.map(val => {
            return { text: val.value, key: "assetCategory", id: val.id };
          })
        );
      }
    },
    pickerConfirm(val) {
      if (val.key == "assetCategory") {
        this.screen.assetCategory = val.id;
        this.filter.status.label = val.text;

        this.showPopup = false;
        this.getInitialData();
        return false;
      }
      this.borrowInfo[val.key] = val.id;
      this.showPopup = false;
    },
    showCalendarBtn(type, key, isClick = false) {
      if (isClick || this.isClick) return false;
      this.calendarType = type;
      this.timeKey = key;
      this.minDate = new Date(this.$moment().add(-1, "y").format("YYYY-MM-DD"));
      this.maxDate = new Date(this.$moment().add(1, "y").format("YYYY-MM-DD"));
      console.log(123, this.borrowInfo.lendingStartDate, this.borrowInfo.lendingEndDate);
      if (key == "lendingStartDate") {
        if (this.borrowInfo.lendingEndDate) this.maxDate = new Date(this.borrowInfo.lendingEndDate);
      } else {
        if (this.borrowInfo.lendingStartDate) this.minDate = new Date(this.borrowInfo.lendingStartDate);
      }
      this.showCalendar = true;
    },
    onConfirm(date) {
      this.showCalendar = false;
      this.borrowInfo[this.timeKey] = this.$moment(date).format("YYYY-MM-DD");
    },
    onFail(arg) {
      arg.errors.forEach(item => {
        this.$notify({ message: item.message, type: "warning" });
      });
    },

    addAsset() {
      if (this.isClick) return false;
      this.screen = {
        assetCategory: "",
        searchContent: "",
        searchKey: "code",

        usingStatus: "AVAILABLE",
        status: "ENABLE"
      };
      this.pager = {
        pageNumber: 1,
        pageSize: 5,
        totalRecords: 0
      };
      this.listStatus = {
        loading: true,
        finished: false
      };
      this.list = [];
      this.getAssets();
      this.getDictsList();
      this.showDialog = true;
      // this.$nextTick(() => {
      //   this.$refs.account.clearSelection();
      // });
    },
    dialogConfirm() {
      let filter = [];
      let selectList = this.list.filter((val, index) => {
        return this.selectList.includes(val.id);
      });
      this.borrowList = this.borrowList.concat(selectList).filter((val, index) => {
        filter.push(val.id);
        return filter.indexOf(val.id) == index;
      });
      if (this.borrowList.length > 0) {
        this.borrowInfo.assetIds = this.borrowList.length;
      } else {
        this.borrowInfo.assetIds = "";
      }
      this.showDialog = false;
    },
    getQuery() {
      let filters = [];
      Object.keys(this.screen).forEach(key => {
        let filter = "equalTo";
        let value = this.screen[key];
        if (!this.screen[key] || key == "searchKey") return false;
        if (key == "searchContent") {
          key = this.screen.searchKey;
          value = this.screen.searchContent;
          filter = "contains";
        }
        if (key == "usingStatus") {
          let query = Filter.OR([Filter.attribute(key)[filter]("SECULAR_RECIPIENT"), Filter.attribute(key)[filter]("AVAILABLE")]);
          filters.push(query);
          return false;
        }
        let query = Filter.attribute(key)[filter](value);
        filters.push(query);
      });
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    },
    onLoad() {
      this.getAssets(true);
    },
    handlePagination(totalPages) {
      if (this.pager.pageNumber < totalPages) {
        // 有更多数据
        this.pager.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    borrowRemove(id) {
      if (this.isClick) return false;
      this.borrowList = this.borrowList.filter((val, index) => {
        return val.id != id;
      });
    },

    onSearch() {
      this.getInitialData();
    },

    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.pager.pageNumber = 1;
      this.getAssets();
    },

    async onSubmit() {
      this.isClick = true;
      let params = {
        lendingEndDate: this.borrowInfo.lendingEndDate,
        lendingStartDate: this.borrowInfo.lendingStartDate,
        pickMobileNumber: this.borrowInfo.pickMobileNumber,
        pickName: this.borrowInfo.pickName,
        storagePlace: this.borrowInfo.storagePlace,
        organizationName: this.borrowInfo.organizationName,
        usingStatus: "LENDING", //使用状态(SECULAR_RECIPIENT(长期领用),TEMPORARY_LENDING(临时借出))
        items: this.borrowList.map(val => {
          return {
            assetId: val.id
          };
        })
      };
      this.postAssetLendings(params);
    },
    //新增资产帐-使用单位
    async postAssetLendings(params) {
      let ret = await this.$api.postAssetLendings({
        params
      });
      if (ret.code != SUCCESS_CODE) return this.$utils.warning("新增失败");
      let ids = this.borrowList.map(val => {
        return val.id;
      });
      this.putAssetsUsingStatus(ids);
    },
    // 修改资产帐使用状态
    async putAssetsUsingStatus(ids) {
      let ret = await this.$api.putAssetsUsingStatus({
        params: { ids, usingStatus: "TEMPORARY_LENDING" }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.$utils.success("新增成功");
      setTimeout(() => {
        this.$router.go(-1);
      }, 1500);
    },
    // 获取资产帐-使用单位详情
    async getAssetLendingsId(id) {
      let ret = await this.$api.getAssetLendingsId({ placeholder: { id } });
      this.borrowInfo = ret.data;
      // this.borrowList = ret.data.items;
      this.borrowList = await Promise.all(
        ret.data.items.map(async val => {
          let srcList = [];
          if (val.attachments.length) {
            srcList = await this.getAppendixes(val.attachments);
          }
          return {
            srcList,
            ...val
          };
        })
      );
      if (this.borrowList.length > 0) {
        this.borrowInfo.assetIds = this.borrowList.length;
      } else {
        this.borrowInfo.assetIds = "";
      }
    },
    // 获取解密图片
    async getAppendixes(par) {
      let urls = par
        .map(val => {
          return val.attachmentUrl;
        })
        .join(",");
      let ret = await this.$api.getAppendixFilePath({ params: { urls } });
      if (ret.data.length) {
        let srcList = ret.data.map(val => {
          return {
            url: val
          };
        });
        return srcList;
      }
      return [];
    },
    // 获取组织列表
    async getOrganizationList() {
      let ret = await this.$api.getOrganizationList();
      if (ret.code !== SUCCESS_CODE) return;
      this.organizationList = ret.data;
    },

    //获取资产
    async getAssets(sign = false) {
      let params = { pageNumber: this.pager.pageNumber - 1, pageSize: this.pager.pageSize };
      params.query = this.getQuery();
      let ret = await this.$api.getAssets({ params });
      this.ids = this.borrowList.map(val => {
        return val.id;
      });
      // this.list = ret.data;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.pager.totalRecords = ret.totalRecords;
      this.selectList = this.borrowList.map(val => {
        return val.id;
      });
      this.handlePagination(ret.totalPages);
      // this.list.forEach(val => {
      //   this.$refs.account.toggleRowSelection(val, this.ids.includes(val.id));
      // });
    },
    // 获取字典 资产分类
    async getDictsList() {
      let ret = await this.$api.getDictsList({
        params: {
          page: false
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.assetsList = ret.data["资产分类"][0].dictOptions;
    }
  },
  created() {
    this.init();
  },
  filters: {
    getStatus(val) {
      if (val == "ENABLE") return "正常";
      if (val == "REPAIR") return "报修";
      if (val == "DAMAGED") return "报损";
      if (val == "DISCARDED") return "报废";
    },
    getUsingStatus(val) {
      if (val == "AVAILABLE") return "可用";
      if (val == "SECULAR_RECIPIENT") return "长期领用";
      if (val == "TEMPORARY_LENDING") return "临时借出";
      if (val == "SECULAR_RECIPIENT_UNDER_REVIEW") return "长期领用审核中";
      if (val == "TEMPORARY_LENDING_UNDER_REVIEW") return "临时借出审核中";
    }
  }
};
</script>
